import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  createOrganizationTeamStat,
  getOrganizationTeam,
  getUserProfile,
  updateCrossFitStats,
  addCrossFitStats
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import QuantityInput from "../AppInput/NumberInput"

function CrossFitStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  playerStatOption,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_workout: '',
    total_points: 0,
    rounds_completed: 0,
    reps_completed: 0,
    time_to_complete: 0,
    total_weight_lifted: 0,
    max_lift: 0,
    total_reps: 0,
    max_unbroken_reps: 0,
    total_workouts: 0,
    event_placements: 0,
    overall_rank: 0,
    open_workouts_completed: 0,
    open_points: 0,
    open_rank: 0,
    training_hours: 0,
    training_intensity: 0,
    skill_improvements: 0,
    loading: false,
    teams: [],
    userProfileData: null
  })
  const {
    date_of_workout,
    total_points,
    rounds_completed,
    reps_completed,
    time_to_complete,
    total_weight_lifted,
    max_lift,
    total_reps,
    max_unbroken_reps,
    total_workouts,
    event_placements,
    overall_rank,
    open_workouts_completed,
    open_points,
    open_rank,
    training_hours,
    training_intensity,
    skill_improvements,
    loading,
    teams,
    userProfileData
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if (visible) {
      getData()
      _getProfileData()
    }
  }, [visible])

  const _getProfileData = async () => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("token")
      const res = await getUserProfile(user?.role === "Parent" ? playerId?.userID : user?.id, token)
      handleChange("userProfileData", res?.data)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_workout", statData?.date_of_workout)
      // getData(`?`)
      if (teams?.length && teams?.some((e) => e?.id == statData?.opponent_team)) {
        setDropDownText(teams?.find((e) => e?.id == statData?.opponent_team)?.team_name)
      }
      handleChange("total_points", statData?.total_points)
      handleChange("rounds_completed", statData?.rounds_completed)
      handleChange("reps_completed", statData?.reps_completed)
      handleChange("time_to_complete", statData?.time_to_complete)
      handleChange("total_weight_lifted", statData?.total_weight_lifted)
      handleChange("max_lift", statData?.max_lift)
      handleChange("total_reps", statData?.total_reps)
      handleChange("max_unbroken_reps", statData?.max_unbroken_reps)
      handleChange("total_workouts", statData?.total_workouts)
      handleChange("event_placements", statData?.event_placements)
      handleChange("overall_rank", statData?.overall_rank)
      handleChange("open_workouts_completed", statData?.open_workouts_completed)
      handleChange("open_points", statData?.open_points)
      handleChange("open_rank", statData?.open_rank)
      handleChange("training_hours", statData?.training_hours)
      handleChange("training_intensity", statData?.training_intensity)
      handleChange("skill_improvements", statData?.skill_improvements)
    } else {
      handleReset()
    }
  }, [statData, visible, teams])

  const handleReset = () => {
    handleChange("date_of_workout", "")
    handleChange("total_points", 0)
    handleChange("rounds_completed", 0)
    handleChange("reps_completed", 0)
    handleChange("time_to_complete", 0)
    handleChange("total_weight_lifted", 0)
    handleChange("max_lift", 0)
    handleChange("total_reps", 0)
    handleChange("max_unbroken_reps", 0)
    handleChange("total_workouts", 0)
    handleChange("event_placements", 0)
    handleChange("overall_rank", 0)
    handleChange("open_workouts_completed", 0)
    handleChange("open_points", 0)
    handleChange("open_rank", 0)
    handleChange("training_hours", 0)
    handleChange("training_intensity", 0)
    handleChange("skill_improvements", 0)
    setDropDownText("")
  }

  const getData = async (payload = '') => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(payload, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_workout === "") {
        alert("Please enter date of workout")
        return
      }
      handleChange("loading", true)
      const payload = {
        date_of_workout,
        total_points,
        rounds_completed,
        reps_completed,
        time_to_complete,
        total_weight_lifted,
        max_lift,
        total_reps,
        max_unbroken_reps,
        total_workouts,
        event_placements,
        overall_rank,
        open_workouts_completed,
        open_points,
        open_rank,
        training_hours,
        training_intensity,
        skill_improvements,
      }
      if (user?.role === "Parent") {
        payload.player_id = playerId?.id // Add player_id if user's role is parent
      }
      await addCrossFitStats(payload, token)
      handleChange("loading", false)
      handleReset()
      handleClose(true)
      enqueueSnackbar(`Stats has been added`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_workout === "") {
        alert("Please enter date of workout")
        return
      }
      handleChange("loading", true)

      if (organization) {
        const payload = {
          date_of_workout,
          organization: organizationData?.id,
          team: teamID
        }
        await createOrganizationTeamStat(payload, token)
      } else {
        const payload = {
          date_of_workout,
          total_points,
          rounds_completed,
          reps_completed,
          time_to_complete,
          total_weight_lifted,
          max_lift,
          total_reps,
          max_unbroken_reps,
          total_workouts,
          event_placements,
          overall_rank,
          open_workouts_completed,
          open_points,
          open_rank,
          training_hours,
          training_intensity,
          skill_improvements,
        }
        if (user?.role === "Parent") {
          payload.player_id = playerId?.id // Add player_id if user's role is parent
        }
        await updateCrossFitStats(statData?.id, payload, token)
      }
      handleChange("loading", false)
      handleReset()
      handleClose(true)
      enqueueSnackbar(`Stats has been added`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const positionName = userProfileData?.player?.first_position_play?.name
  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <Grid container spacing={3}>
          {user?.role == "Parent" && (
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  label={"Adding stats for"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={playerId?.name}
                  name={"playerId"}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <AppInput
              label={"Date of workout"}
              max={moment().format("YYYY-MM-DD")}
              type={"date"}
              height={40}
              required={true}
              onChange={handleChange}
              value={date_of_workout}
              name={"date_of_workout"}
            />
            {dateOfGameError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Total Points"}
              onChange={handleChange}
              value={total_points}
              name={"total_points"}
            />
          </Grid>
          {
            positionName === "MetCon" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Rounds Completed"}
                  onChange={handleChange}
                  value={rounds_completed}
                  name={"rounds_completed"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Reps Completed"}
                  onChange={handleChange}
                  value={reps_completed}
                  name={"reps_completed"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Time to Complete"}
                  onChange={handleChange}
                  value={time_to_complete}
                  name={"time_to_complete"}
                />
              </Grid>
            </>
          }
          {
            positionName === "Weightlifting" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Total Weight Lifted"}
                  onChange={handleChange}
                  value={total_weight_lifted}
                  name={"total_weight_lifted"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Max Lift"}
                  onChange={handleChange}
                  value={max_lift}
                  name={"max_lift"}
                />
              </Grid>
            </>
          }
          {
            positionName === "Gymnastics" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Total Reps"}
                  onChange={handleChange}
                  value={total_reps}
                  name={"total_reps"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Max Unbroken Reps"}
                  onChange={handleChange}
                  value={max_unbroken_reps}
                  name={"max_unbroken_reps"}
                />
              </Grid>
            </>
          }
          {
            positionName === "Crossfit Games" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Total Workouts"}
                  onChange={handleChange}
                  value={total_workouts}
                  name={"total_workouts"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Event Placements"}
                  onChange={handleChange}
                  value={event_placements}
                  name={"event_placements"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Overall Rank"}
                  onChange={handleChange}
                  value={overall_rank}
                  name={"overall_rank"}
                />
              </Grid>
            </>
          }
          {
            positionName === "Crossfit Open" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Open Workouts Completed"}
                  onChange={handleChange}
                  value={open_workouts_completed}
                  name={"open_workouts_completed}"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Open Points"}
                  onChange={handleChange}
                  value={open_points}
                  name={"open_points"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Open Rank"}
                  onChange={handleChange}
                  value={open_rank}
                  name={"open_rank"}
                />
              </Grid>
            </>
          }
          {
            positionName === "Training Events" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Training Hours"}
                  onChange={handleChange}
                  value={training_hours}
                  name={"training_hours}"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Training Intensity"}
                  onChange={handleChange}
                  value={training_intensity}
                  name={"training_intensity"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Skill Improvements"}
                  onChange={handleChange}
                  value={skill_improvements}
                  name={"skill_improvements"}
                />
              </Grid>
            </>
          }
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            // disabled={disabled}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default CrossFitStats
