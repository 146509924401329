import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  createOrganizationTeamStat,
  getOrganizationTeam,
  getUserProfile,
  updateRunningStats,
  addRunningStats
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import QuantityInput from "../AppInput/NumberInput"
import { convertSecondsToTime, convertTimeToSeconds } from "../../utils/mixed"

function RunningStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  playerStatOption,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_run: '',
    distance_ran: 0,
    steps: 0,
    time: 0,
    elevation_gain: 0,
    marathon_time: 0,
    half_marathon_time: 0,
    ten_k_time: 0,
    five_k_time: 0,
    ultra_marathon_time: 0,
    relay_marathon_time: 0,
    loading: false,
    teams: [],
    userProfileData: null,
    isErrorOccur: false
  })
  const {
    date_of_run,
    distance_ran,
    steps,
    time,
    elevation_gain,
    marathon_time,
    half_marathon_time,
    ten_k_time,
    five_k_time,
    ultra_marathon_time,
    relay_marathon_time,
    loading,
    teams,
    userProfileData,
    isErrorOccur
  } = state

  const handleChange = (key, value, isErrorOccur = false) => {
    setState(pre => ({ ...pre, [key]: value, isErrorOccur: isErrorOccur }))
  }

  useEffect(() => {
    if (visible) {
      getData()
      _getProfileData()
    }
  }, [visible])

  const _getProfileData = async () => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("token")
      const res = await getUserProfile(user?.role === "Parent" ? playerId?.userID : user?.id, token)
      handleChange("userProfileData", res?.data)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_run", statData?.date_of_run)
      // getData(`?`)
      if (teams?.length && teams?.some((e) => e?.id == statData?.opponent_team)) {
        setDropDownText(teams?.find((e) => e?.id == statData?.opponent_team)?.team_name)
      }
      // handleChange("opponent_team", "")
      handleChange("distance_ran", statData?.distance_ran)
      handleChange("steps", statData?.steps)
      handleChange("time", convertSecondsToTime(statData?.time))
      handleChange("marathon_time", convertSecondsToTime(statData?.marathon_time))
      handleChange("half_marathon_time", convertSecondsToTime(statData?.half_marathon_time))
      handleChange("ten_k_time", convertSecondsToTime(statData?.ten_k_time))
      handleChange("five_k_time", convertSecondsToTime(statData?.five_k_time))
      handleChange("ultra_marathon_time", convertSecondsToTime(statData?.ultra_marathon_time))
      handleChange("relay_marathon_time", convertSecondsToTime(statData?.relay_marathon_time))
    } else {
      handleReset()
    }
  }, [statData, visible, teams])

  const handleReset = () => {
    handleChange("date_of_run", "")
    // handleChange("opponent_team", "")
    handleChange("distance_ran", 0)
    handleChange("steps", 0)
    handleChange("time", 0)
    handleChange("marathon_time", 0)
    handleChange("half_marathon_time", 0)
    handleChange("ten_k_time", 0)
    handleChange("five_k_time", 0)
    handleChange("ultra_marathon_time", 0)
    handleChange("relay_marathon_time", 0)
    setDropDownText("")
  }

  const getData = async (payload = '') => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(payload, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_run === "") {
        alert("Please enter date of race")
      }
      // if (opponent_team === "" && opponent_team_custom === "") {
      //   alert("Please enter team played")
      // } else {
      handleChange("loading", true)
      const payload = {
        date_of_run,
        distance_ran,
        steps,
        time: convertTimeToSeconds(time),
        elevation_gain,
        marathon_time: convertTimeToSeconds(marathon_time),
        half_marathon_time: convertTimeToSeconds(half_marathon_time),
        ten_k_time: convertTimeToSeconds(ten_k_time),
        five_k_time: convertTimeToSeconds(five_k_time),
        ultra_marathon_time: convertTimeToSeconds(ultra_marathon_time),
        relay_marathon_time: convertTimeToSeconds(relay_marathon_time),
      }
      if (user?.role === "Parent") {
        payload.player_id = playerId?.id // Add player_id if user's role is parent
      }
      await addRunningStats(payload, token)
      handleChange("loading", false)
      handleReset()
      handleClose(true)
      enqueueSnackbar(`Stats has been added`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      // }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_run === "") {
        alert("Please enter date of race")
      }
      // if (opponent_team === "" && opponent_team_custom === "") {
      //   alert("Please enter team played")
      // } else {
      handleChange("loading", true)
      if (organization) {
        const payload = {
          date_of_run,
          // opponent_team,
          organization: organizationData?.id,
          team: teamID
        }
        await createOrganizationTeamStat(payload, token)
      } else {
        const payload = {
          date_of_run,
          distance_ran,
          steps,
          time: convertTimeToSeconds(time),
          elevation_gain,
          marathon_time: convertTimeToSeconds(marathon_time),
          half_marathon_time: convertTimeToSeconds(half_marathon_time),
          ten_k_time: convertTimeToSeconds(ten_k_time),
          five_k_time: convertTimeToSeconds(five_k_time),
          ultra_marathon_time: convertTimeToSeconds(ultra_marathon_time),
          relay_marathon_time: convertTimeToSeconds(relay_marathon_time),
        }
        if (user?.role === "Parent") {
          payload.player_id = playerId?.id // Add player_id if user's role is parent
        }
        await updateRunningStats(statData?.id, payload, token)
      }
      handleChange("loading", false)
      handleReset()
      handleClose(true)
      enqueueSnackbar(`Stats has been added`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      // }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const positionName = userProfileData?.player?.first_position_play?.name
  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <Grid container spacing={3}>
          {user?.role == "Parent" && (
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  label={"Adding stats for"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={playerId?.name}
                  name={"playerId"}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <AppInput
              label={"Date of run"}
              max={moment().format("YYYY-MM-DD")}
              type={"date"}
              height={40}
              required={true}
              onChange={handleChange}
              value={date_of_run}
              name={"date_of_run"}
            />
            {dateOfGameError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"Distance Ran"}
              onChange={handleChange}
              value={distance_ran}
              name={"distance_ran"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"Steps"}
              onChange={handleChange}
              value={steps}
              name={"steps"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"Time"}
              isTime={true}
              onChange={handleChange}
              value={time}
              name={"time"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"Elavation Gain"}
              onChange={handleChange}
              value={elevation_gain}
              name={"elevation_gain"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"Marathon Time"}
              isTime={true}
              onChange={handleChange}
              value={marathon_time}
              name={"marathon_time"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"Half Marathon Time"}
              isTime={true}
              onChange={handleChange}
              value={half_marathon_time}
              name={"half_marathon_time"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"10K Time"}
              isTime={true}
              onChange={handleChange}
              value={ten_k_time}
              name={"ten_k_time"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"5K Time"}
              isTime={true}
              onChange={handleChange}
              value={five_k_time}
              name={"five_k_time"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"Ultra Marathon Time"}
              isTime={true}
              onChange={handleChange}
              value={ultra_marathon_time}
              name={"ultra_marathon_time"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              isNormalInput={true}
              label={"Relay Marathon Time"}
              isTime={true}
              onChange={handleChange}
              value={relay_marathon_time}
              name={"relay_marathon_time"}
            />
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            disabled={isErrorOccur}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default RunningStats
