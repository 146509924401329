import * as React from "react"
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput"
import { styled } from "@mui/system"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import Label from "./Label"
import { useState } from 'react';

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: "increment"
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />
        }
      }}
      {...props}
      ref={ref}
    />
  )
})


export default function QuantityInput({ isTime, isNormalInput, label, onChange, value, name }) {
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    if (isTime) {
      onChange(name, value);
      const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5]?[0-9]):([0-5]?[0-9](\.[0-9]+)?)$/;
      if (timeRegex.test(value)) {
        setError('');
        onChange(name, value, false);
      } else {
        onChange(name, value, true);
        setError('Invalid time format. Please use hh:mm:ss or hh:mm:ss.sss');
      }
    } else {
      setError('');
      onChange(name, value, false);
    }
  };

  return (
    <div>
      <Label fontNormal text={label} />
      {
        isNormalInput ? (
          <input
            min={0}
            max={1000}
            type={isTime ? "text" : "number"}
            value={value === 0 ? "" : value}
            placeholder={isTime ? "00:00:00.00" : "0"}
            style={{
              minWidth: "100%",
              height: "50px",
              textAlign: "center",
              borderRadius: 8,
              border: "1px solid rgb(202, 217, 233)",
              backgroundColor: "rgb(255, 255, 255) !important"
            }}
            onChange={handleChange}
          />
        ) : (
          <NumberInput
            aria-label="Quantity Input"
            min={0}
            max={1000}
            value={value}
            onChange={(value, newValue) => {
              onChange(name, newValue);
            }}
          />
        )
      }
      {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
    </div>
  );
}

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99"
}

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025"
}

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
)

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
    };
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 60%;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[700] : blue[200]};
  }

  &:focus-visible {
    outline: 0;
  }
`
)

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  background: ${theme.palette.mode === "dark" ? blue[500] : "#0059c1"};
  color: ${theme.palette.mode === "dark" ? grey[50] : "#fff"};
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

//   &:hover {
//     cursor: pointer;
//     background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
//     border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
//     color: ${grey[50]};
//   }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
  @media (min-width: 344px) and (max-width: 599px) {
    min-width: 15%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    min-width: 15%;
  }
`
)
