import "./App.css"
import React, { useEffect, useState } from "react"
import { BrowserRouter, Navigate, Route, Routes, useLocation, useParams } from "react-router-dom"
import { AddPost, PrivateRoute } from "./components"
import { createTheme, ThemeProvider } from "@mui/material/styles"

import AvatarIcon from "./assets/images/avatar.png"
//ROUTES
import * as ROUTES from "./constants/routes"

//CONTAINERS
import {
  Home,
  PrivacyPolicy,
  TermsConditions,
  Login,
  Signup,
  OTPAuth,
  ForgotPassword,
  ChangePassword,
  Dashboard,
  PlayerProfile,
  PlayerProfileForm,
  Teams,
  AddTeam,
  Bookmark,
  ParentProfileForm,
  OrganizationProfileForm,
  OrganizationProfile,
  SearchPage,
  Settings,
  AdminLogin,
  AdminUsers,
  AdminPlayerProfileForm,
  AdminParentProfileForm,
  AdminOrganizationProfileForm,
  ParentProfile,
  CoachProfile,
  CoachProfileForm,
  AppCalendar,
  GameSchedule
} from "./containers"
import AppContext from "./Context"
import "./styles.css"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { SnackbarProvider } from "notistack"
import ResetPass from "./containers/ChangePassword/ResetPass"
import { getAllUsers } from "./api/admin"
import {
  getChannels,
  getGrades,
  getHashtags,
  getMotivations,
  getNotifications,
  getOrganizationProfile,
  getPlayerToFav,
  getPolicy,
  getPositions,
  getProfile,
  getSchools,
  getSports,
  getTerms,
  getUserProfile,
  upcomingEvents
} from "./api/auth"
import LocationAccess from "./containers/Login/LocationAccess"
import Roster from "./containers/Roster"
import AddPlayer from "./containers/AddPlayer"
import InvitationAccept from "./containers/InvitationAccept"
import InvitationReject from "./containers/InvitationReject"
import PotentialPlayer from "./containers/PotentialPlayer"
import Notifications from "./containers/Notifications"
import DetailPost from "./containers/Post/DetailPost"
import ChatScreen from "./containers/Messages/Chat"
import AdminUserFeedBack from "./containers/Admin/AdminUserFeedBack"
import AdminReportedUser from "./containers/Admin/AdminReportedUsers"
import LinkingRequest from "./containers/LinkingRequest"
import StatsTable from "./containers/PlayerStats"
import LinkedPLayers from "./containers/LinkedPlayer"

const theme = createTheme({
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        zIndex: 1300,
      },
    },
  },
})
function App() {
  const [hashtagsText, setHashtagsText] = useState('')
  const [user, setUser] = useState(null)
  const [fetchPost, setFetchPost] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [messageUnreadCount, setMessageUnreadCount] = useState(null)
  const [organizationData, setOrganizationData] = useState(null)
  const [userProfile, setUserProfile] = useState(null)
  const [positionList, setPositionList] = useState([])
  const [allUpcomingEvents, setUpcomingEvents] = useState([])
  const [hashtagsList, setHashtags] = useState([])
  const [motivations, setMotivations] = useState([])
  const [sports, setSports] = useState([])
  const [schools, setSchools] = useState([])
  const [grades, setGrades] = useState([])
  const [locationParams, setLocationParams] = useState(null)
  const [dropoffParams, setDropoffParams] = useState(null)
  const [pickupParams, setPickupParams] = useState(null)
  const [allUsers, setAllUsers] = useState([])
  const [playerToFav, setPlayerToFav] = useState([])
  const [terms, setTerms] = useState([])
  const [privacyPolicy, setPrivacyPolicy] = useState([])
  const [addPost, setAddPost] = useState(false)
  const isPlayer = user?.role === "Player"
  const isParent = user?.role === "Parent"
  const isCoach = user?.role === "Coach"
  const isOrganization = user?.role === "Organization"
  const ProfileDP = isPlayer
    ? userProfile?.player?.picture || AvatarIcon
    : isParent
      ? userProfile?.parent?.picture || AvatarIcon
      : isOrganization
        ? organizationData?.logo || AvatarIcon
        : isCoach
          ? userProfile?.coach?.picture || AvatarIcon
          : user?.profile?.picture || AvatarIcon
  const ProfileName = isOrganization
    ? organizationData?.organization_name
    : user?.name || userProfile?.name || "Guest"
  let userData = localStorage.getItem("user")
  let token = localStorage.getItem("token")
  const [apiCallsMade, setApiCallsMade] = useState(false);

  useEffect(() => {
    _getTerms()
    if (userData && token && !apiCallsMade) {
      const user = JSON.parse(userData)
      setUser(user)
      _getProfile(user?.id)
      _getPositions("")
      _getGrades()
      _getPlayerToFav()
      _getHashtags()
      _getMotivations()
      _getSports()
      _getNotifications()
      _getChannelUnreadCount()
      setApiCallsMade(true);
    }
  }, [userData, apiCallsMade])

  const handleClosePost = () => {
    setAddPost(false)
  }

  const handleOpenPost = () => {
    setAddPost(true)
  }

  const resetState = () => {
    setUser(null)
    setGrades([])
    setHashtags([])
    setPlayerToFav([])
    setPositionList([])
    setMotivations([])
    setSports([])
    setSchools([])
    setUserProfile(null)
    setOrganizationData(null)
  }

  const _getProfile = async id => {
    try {
      const token = localStorage.getItem("token")
      if (id) {
        const res = await getProfile(id || user?.id, token)
        localStorage.setItem("user", JSON.stringify(res?.data))
        setUser(res?.data)
        if (res?.data?.role === "Organization") {
          const organization = await getOrganizationProfile(token)
          setOrganizationData(organization?.data?.results[0])
        } else if (id || user?.id || res?.data?.id) {
          const user = await getUserProfile(
            id || user?.id || res?.data?.id,
            token
          )
          setUserProfile(user?.data)
        }
      }
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  const _getPlayerToFav = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getPlayerToFav(token)
      setPlayerToFav(res?.data)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _getHashtags = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getHashtags(token)
      setHashtags(res?.data?.results)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getMotivations = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getMotivations(token)
      setMotivations(res?.data?.results)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _getSports = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getSports(token)
      setSports(res?.data)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getSchools = async payload => {
    try {
      const token = localStorage.getItem("token")
      const res = await getSchools(payload, token)
      setSchools(res?.data?.results)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getAllUsers = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getAllUsers("", token)
      setAllUsers(res?.data)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getPositions = async payload => {
    try {
      const queryParams = payload || ""
      const token = localStorage.getItem("token")
      const res = await getPositions(queryParams, token)
      setPositionList(res?.data)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _upcomingEvents = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await upcomingEvents(token)
      setUpcomingEvents(res?.data)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getGrades = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getGrades(token)
      setGrades(res?.data?.results)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getTerms = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getTerms(token)
      const res1 = await getPolicy(token)
      setTerms(res?.data?.results)
      setPrivacyPolicy(res1?.data?.results)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getNotifications = async () => {
    try {
      const token = localStorage.getItem("token")
      const res = await getNotifications("", token)
      setNotifications(res?.data?.results)
      let count = 0
      res?.data?.results?.forEach(element => {
        if (
          typeof element?.unread_count === "number" &&
          element?.unread_count > 0
        ) {
          count = element?.unread_count
        }
      })
      setNotificationsCount(count)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _getChannelUnreadCount = async () => {
    try {
      const res = await getChannels()
      setMessageUnreadCount(res?.data?.total_unread_messages)
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const RedirectWithExclusions = () => {
    const location = useLocation();

    // Exclude requests for ".well-known" or other static assets
    if (location.pathname.startsWith("/.well-known")) {
      return null; // Do nothing, allow static file to load
    }

    return <Navigate to="/" replace={true} />;
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        userData,
        setLocationParams,
        locationParams,
        setPickupParams,
        pickupParams,
        setDropoffParams,
        dropoffParams,
        allUsers,
        _getAllUsers,
        _getProfile,
        isPlayer,
        isParent,
        isOrganization,
        organizationData,
        setOrganizationData,
        userProfile,
        setUserProfile,
        positionList,
        grades,
        playerToFav,
        resetState,
        _getPlayerToFav,
        hashtagsList,
        _getHashtags,
        terms,
        privacyPolicy,
        handleOpenPost,
        handleClosePost,
        addPost,
        ProfileDP,
        ProfileName,
        isCoach,
        fetchPost,
        setFetchPost,
        motivations,
        _getMotivations,
        _getSports,
        sports,
        setSports,
        _getPositions,
        _getSchools,
        setSchools,
        schools,
        setNotifications,
        notifications,
        _getNotifications,
        _getChannelUnreadCount,
        messageUnreadCount,
        notificationsCount,
        hashtagsText,
        setHashtagsText,
        allUpcomingEvents,
        _upcomingEvents,
        _getGrades
      }}
    >
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path={ROUTES.MAIN} element={<Home />} />
            {/* Admin Routes */}
            <Route path={ROUTES.ADMINLOGIN} element={<AdminLogin />} />
            <Route path={ROUTES.ADMINUSERS} element={<AdminUsers />} />
            <Route
              path={ROUTES.ADMINFEEDBACKUSERS}
              element={<AdminUserFeedBack />}
            />
            <Route
              path={ROUTES.ADMINREPORTEDUSERS}
              element={<AdminReportedUser />}
            />
            <Route
              path={ROUTES.ADMINPLAYERPROFILE}
              element={<AdminPlayerProfileForm />}
            />
            <Route
              path={ROUTES.ADMINPARENTPROFILE}
              element={<AdminParentProfileForm />}
            />
            <Route
              path={ROUTES.ADMINORGANIZATIONPROFILE}
              element={<AdminOrganizationProfileForm />}
            />
            {/* Web App Routes */}
            <Route
              path={ROUTES.INVITATIONACCEPT}
              element={<InvitationAccept />}
            />
            <Route
              path={ROUTES.INVITATIONREJECT}
              element={<InvitationReject />}
            />
            <Route
              path={ROUTES.POTENTIALPLAYERS}
              element={<PotentialPlayer />}
            />
            <Route
              path={ROUTES.DASHBOARD}
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            {/* <Route path={ROUTES.LINK_ACTION} element={<Login />} /> */}
            <Route
              path={ROUTES.LINK_ACTION}
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.LINKING_REQUEST}
              element={
                <PrivateRoute>
                  <LinkingRequest />
                </PrivateRoute>
              }
            />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.SIGNUP} element={<Signup />} />
            <Route path={ROUTES.OTPAUTH} element={<OTPAuth />} />
            <Route path={ROUTES.LOCATIONACCESS} element={<LocationAccess />} />
            <Route path={ROUTES.FORGOTPASSWORD} element={<ForgotPassword />} />
            <Route
              path={ROUTES.CHANGEPASSWORD}
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route path={ROUTES.RESETPASSWORD} element={<ResetPass />} />
            <Route
              path={ROUTES.TERMSCONDITIONS}
              element={<TermsConditions />}
            />
            <Route path={ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
            <Route
              path={ROUTES.PLAYERPROFILE}
              element={
                <PrivateRoute>
                  <PlayerProfile />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.NOTIFICATIONS}
              element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.LINKED_PLAYERS}
              element={
                <PrivateRoute>
                  <LinkedPLayers />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.DETAILPOST}
              element={
                <PrivateRoute>
                  <DetailPost />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MESSAGES}
              element={
                <PrivateRoute>
                  <ChatScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CHAT_MESSAGE}
              element={
                <PrivateRoute>
                  <ChatScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.PLAYERPROFILEFORM}
              element={
                <PrivateRoute>
                  <PlayerProfileForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.PLAYERPROFILEFORMADD}
              element={
                <PrivateRoute>
                  <PlayerProfileForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.TEAMS}
              element={
                <PrivateRoute>
                  <Teams />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.GAMESCHEDULE}
              element={
                <PrivateRoute>
                  <GameSchedule />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.ADDTEAM}
              element={
                <PrivateRoute>
                  <AddTeam />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.EDITTEAM}
              element={
                <PrivateRoute>
                  <AddTeam />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.APPCALENDAR}
              element={
                <PrivateRoute>
                  <AppCalendar />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.BOOKMARK}
              element={
                <PrivateRoute>
                  <Bookmark />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.COACHPROFILE}
              element={
                <PrivateRoute>
                  <CoachProfile />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.COACHPROFILEFORM}
              element={
                <PrivateRoute>
                  <CoachProfileForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.COACHPROFILEFORMADD}
              element={
                <PrivateRoute>
                  <CoachProfileForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.PARENTPROFILE}
              element={
                <PrivateRoute>
                  <ParentProfile />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.PARENTPROFILEFORM}
              element={
                <PrivateRoute>
                  <ParentProfileForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.PARENTPROFILEFORMADD}
              element={
                <PrivateRoute>
                  <ParentProfileForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.ORGANIZATIONPROFILE}
              element={
                <PrivateRoute>
                  <OrganizationProfile />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.ORGANIZATIONPROFILEFORM}
              element={
                <PrivateRoute>
                  <OrganizationProfileForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.ORGANIZATIONPROFILEFORMADD}
              element={
                <PrivateRoute>
                  <OrganizationProfileForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.SEARCH}
              element={
                <PrivateRoute>
                  <SearchPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.SETTINGS}
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.ROSTER}
              element={
                <PrivateRoute>
                  <Roster />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.ADDPLAYER}
              element={
                <PrivateRoute>
                  <AddPlayer />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.PLAYERSTATS}
              element={
                <PrivateRoute>
                  <StatsTable />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<RedirectWithExclusions />} />
          </Routes>
          <AddPost />
        </ThemeProvider>
      </SnackbarProvider>
    </AppContext.Provider>
  )
}

export default App
